import {Helmet} from "react-helmet";
import React, { useEffect, useState, useContext } from "react";
import locomotiveScroll from "locomotive-scroll";
import { motion } from "framer-motion";
import DataContext  from 'contexts/dataContext';
import {  useParams } from 'react-router-dom'
import Button from "components/ui/button/index.jsx";
import Tag from "components/ui/tag/index.jsx";
import "styles/pages/report.scss";

function CompanyReport() {
  //Contexts
  const [contextData, setContextData] = useContext(DataContext);
  const [ready, setReady] = useState(false);
  const { id } = useParams();
  const [title, setTitle] = useState("Plataforma");
  //Data splitted
  const [productCategories, setProductCategories] = useState();
  const [cost, setCost] = useState();
  const [target, setTarget] = useState();
  const [language, setLanguage] = useState();
  const [builder, setBuilder] = useState();
  const [channels, setChannels] = useState();
  const [industries, setIndustries] = useState();
  const [integrations, setIntegrations] = useState();
  const [ai, setAI] = useState();
  const [weekness, setWeekness] = useState();
  const [strenghts, setStrenghts] = useState();
  const [analytics, setAnalytics] = useState();

  //DATA CONTEXT
  useEffect(()=> {
    if(contextData){

        setReady(true)
        //scroll.scrollTo("top");

    }
  }, [contextData]);

  //SCROLL HANDLE
  //Scroll Div
  const scrollRef = React.createRef();
  const [scroll, setScroll] = useState();

  useEffect(() => {

    //SET GROUPS TO render
    if(contextData && contextData.data[id]){
      setTitle(contextData.data[id].Name);
      setProductCategories(contextData.data[id].['Product Category'].split(', '));
      setCost(contextData.data[id].['Cost'].split(', '));
      setTarget(contextData.data[id].['Target Company Size'].split(', '));
      setLanguage(contextData.data[id].['Multi Language'].split(', '));
      setBuilder(contextData.data[id].['Builder'].split(', '));
      setChannels(contextData.data[id].['Channels'].split(', '));
      setIntegrations(contextData.data[id].['Integrations'].split(', '));
      setIndustries(contextData.data[id].['Industries'].split(', '));
      setAI(contextData.data[id].['AI'].split(', '));
      setAnalytics(contextData.data[id].['Analytics'].split(', '));
      setWeekness(contextData.data[id].['Weekness']);
      setStrenghts(contextData.data[id].['Strenghts']);
    }
    //
    var scrollBars = document.getElementsByClassName("c-scrollbar");
    while(scrollBars.length > 0){
         scrollBars[0].parentNode.removeChild(scrollBars[0]);
     }
      if(!scroll){
        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          reloadOnContextChange: true,
          smooth: true
        });
        setScroll(scrollObj);
      }else {
        scroll.destroy();

        var newScrollBars = document.getElementsByClassName("c-scrollbar");
        while(newScrollBars.length > 0){
             newScrollBars[0].parentNode.removeChild(newScrollBars[0]);
         }

        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          smooth: true
        });
        setScroll(scrollObj);
      }
  }, [contextData]);






  return (
    <>
      <Helmet>
          <title>{title}. BeBot Conversational Landscape</title>
      </Helmet>
      <main className="main white report" id="scroll-report" data-scroll-container ref={scrollRef} >
        <motion.section exit={{ opacity: 0 }}>
          {contextData && contextData.data.length > 0 && (
          <div data-scroll-section id="reportTarget">
            <div className="reportNav" data-scroll data-scroll-sticky data-scroll-target="#reportTarget">
              <Button small to="/" type="ghost" >Volver</Button>
              <div className="reportNavEdit">
                <Button small external to={contextData.data[id].Link}>Editar este reporte</Button>
              </div>
            </div>


            <div className="reportBody" data-scroll data-scroll-speed="2">

              <div className="reportContent" data-scroll>
                <div className="reportHeader" data-scroll data-scroll-speed="1">
                  <div data-scroll data-scroll-speed="1" >
                    <Tag color={contextData.data[id].Status}>{contextData.data[id].Status}</Tag>  <Tag>Autor: {contextData.data[id].Email}</Tag>
                  </div>
                  <h1 data-scroll data-scroll-speed="0">{contextData.data[id].Name} </h1>


                </div>
                <div className="reportContentCopy" data-scroll>
                  <div className="copyBlock">
                    <h3>Resumen</h3>
                    <p>{contextData.data[id].Description}</p>
                    {strenghts &&  (
                        <p><strong>Fortalezas:</strong> {contextData.data[id].Strenghts}</p>
                    )}
                    {weekness &&  (
                      <p><strong>Debilidades:</strong>  {contextData.data[id].Weekness}</p>
                    )}
                  </div>
                  <div className="copyBlock">
                    <h3>Informe</h3>
                    <p>Informe aún no disponible.</p>
                  </div>




                </div>
              </div>
              <div className="reportCard"  data-scroll  data-scroll-sticky data-scroll-target="#reportTarget" data-scroll-offset="200">
                  <div className="reportCardContainer" data-scroll-section>
                    <div className="cardCategory">
                      <h4>Website</h4>
                      <Tag small color="Link"><a target="_blank" rel="noreferrer"  href={contextData.data[id].Website}>{contextData.data[id].Website}</a></Tag>

                    </div>
                    <div className="cardCategory">
                      <h4>Estado del reporte</h4>
                      <Tag small color={contextData.data[id].Status}>{contextData.data[id].Status}</Tag>

                    </div>
                    <div className="cardCategory">
                      <h4>Categoría de la plataforma</h4>
                      <div>
                      {productCategories && productCategories.map((item, index) => (
                        <Tag  key={index} small>{item}</Tag>

                      )) }
                      </div>
                    </div>
                    {industries && industries[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Industrias</h4>
                        <div>
                        {industries.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {cost && cost[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Precio</h4>
                        <div>
                        {cost.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {target && target[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Empresas Target</h4>
                        <div>
                        {target.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {ai && ai[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Inteligencia Artificial</h4>
                        <div>
                        {ai.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {builder && builder[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Tipo de Builder</h4>
                        <div>
                        {builder.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {channels && channels[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Canales</h4>
                        <div>
                        {channels.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {integrations && integrations[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Integraciones</h4>
                        <div>
                        {integrations.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {language && language[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Multi-lenguaje</h4>
                        <div>
                        {language.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}
                    {analytics && analytics[0].length > 0 && (
                      <div className="cardCategory">
                        <h4>Herramienta de métricas</h4>
                        <div>
                        {analytics.map((item, index) => (
                          <Tag  key={index} small>{item}</Tag>

                        )) }
                        </div>
                      </div>
                    )}



                  </div>
              </div>


            </div>

          </div>


        )}
        </motion.section>
      </main>

    </>
  );
}

export default CompanyReport;
