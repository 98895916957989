import {Helmet} from "react-helmet";
import React, { useEffect, useState, useContext } from "react";
import locomotiveScroll from "locomotive-scroll";
import { motion } from "framer-motion";
import Header from "components/ui/header/index.jsx";
import Filters from "components/ui/filters/index.jsx";
import FilterItem from "components/ui/filterItem/index.jsx";
import Footer from "components/ui/footer/index.jsx";
import AddCompanyBlock from "components/ui/addCompanyBlock/index.jsx";
import DataContext  from 'contexts/dataContext';
import CompanyItem from "components/ui/companyItem/index.jsx";
import Button from "components/ui/button/index.jsx";

function Home({ callback}) {
  //Contexts
  const [contextData, setContextData] = useContext(DataContext);
  const [contextFilter, setContextFilter] = useContext(DataContext);
  const [productCategories, setProductCategories] = useState([]);
  const [industriesCategories, setIndustriesCategories] = useState([]);
  const [integrationsCategories, setIntegrationsCategories] = useState([]);
  const [channelsCategories, setChannelsCategories] = useState([]);
  const [ready, setReady] = useState(false);
  const [homeFilter, setHomeFilter] = useState(false);
  //actives
  const [activeCount, setActiveCount] = useState(0);
  const [activeElements, setActiveElements] = useState(0);
  const [halfActiveElements, setHalfActiveElements] = useState(0);
  //Filter refresh
  const [clear, setClear] = useState(0);
  //DATA CONTEXT
  useEffect(()=> {
    if(contextData.data){
        setReady(true);
    }
  });
  //Scroll
  const scrollRef = React.createRef();
  const [scroll, setScroll] = useState();
  useEffect(() => {
      if(!scroll){
        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          reloadOnContextChange: true,
          smooth: true
        });
        setScroll(scrollObj);
      }else {
        scroll.destroy();

        var newScrollBars = document.getElementsByClassName("c-scrollbar");
        while(newScrollBars.length > 0){
             newScrollBars[0].parentNode.removeChild(newScrollBars[0]);
         }

        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          smooth: true
        });
        setScroll(scrollObj);
      }
  }, [ready]);


  //DATA READY AND PARSED
  //CREATE FILTER AND SET CONTEXT
  useEffect(() => {

    //Detect values by column
    var productCategoriesArray = [];
    var industryCategoriesArray = [];
    var integrationsCategoriesArray = [];
    var channelsCategoriesArray = [];

    if(contextData.data && contextData.data.length > 0){

      contextData.data.forEach((item, index) => {
        let productData = item[ 'Product Category' ];
        let industryData = item[ 'Industries' ];
        let channelsData = item[ 'Channels' ];
        let integrationsData = item[ 'Integrations' ];
        if(productData ){
          let productDataParsed = productData.split(', ')
          productDataParsed.forEach((itemProduct, index) => {
            if(productCategoriesArray.indexOf(itemProduct) === -1 && productCategoriesArray.indexOf(" "+itemProduct) === -1) {
                productCategoriesArray.push(itemProduct);
            }
          });
        }

        if(industryData ){
          let industryDataParsed = industryData.split(', ')
          industryDataParsed.forEach((itemIndustry, index) => {
            if(industryCategoriesArray.indexOf(itemIndustry) === -1 && industryCategoriesArray.indexOf(" "+itemIndustry) === -1) {
                industryCategoriesArray.push(itemIndustry);
            }
          });
        }

        if(channelsData ){
          let channelsDataParsed = channelsData.split(', ')
          channelsDataParsed.forEach((itemChannel, index) => {
            if(channelsCategoriesArray.indexOf(itemChannel) === -1 && channelsCategoriesArray.indexOf(" "+itemChannel) === -1) {
                channelsCategoriesArray.push(itemChannel);
            }
          });
        }

        if(integrationsData ){
          let integrationsDataParsed = integrationsData.split(', ')
          integrationsDataParsed.forEach((itemIntegrations, index) => {
            if(integrationsCategoriesArray.indexOf(itemIntegrations) === -1 && integrationsCategoriesArray.indexOf(" "+itemIntegrations) === -1) {
                integrationsCategoriesArray.push(itemIntegrations);
            }
          });
        }

      });
      setProductCategories(productCategoriesArray);
      setIndustriesCategories(industryCategoriesArray);
      setChannelsCategories(channelsCategoriesArray);
      setIntegrationsCategories(integrationsCategoriesArray);
    }
  }, [ready]);


  //FILTER UPDATED
  useEffect(() => {
    setHomeFilter(contextFilter);
  }, [contextFilter]);

  //ITEMS COUNT
  useEffect(() => {
    var actives = document.getElementsByClassName("companyItem active");
    var halfActives = document.getElementsByClassName("companyItem halfActive");

    setActiveElements(actives.length)
    setHalfActiveElements(halfActives.length)

  },[activeCount]);

  //CHANGE FILTER CALLBACK
  function handleItemChange(value) {
      setActiveCount(activeCount + 1);
  }

  //CLEAN FILTERS function
  function handleClearFilters(e) {
    e.preventDefault();
    callback("Todos", "Todos");
    setClear(clear+1);

  }

  //FILTER UPDATED
  useEffect(() => {


  }, [homeFilter]);

  //FILTER UPDATED
  useEffect(() => { }, [clear]);





  return (
    <>
      <Helmet>
        <title>BeBot Conversational Landscape</title>
      </Helmet>
      <main className="main" id="scroll-home" data-scroll-container ref={scrollRef} >
        <motion.section exit={{ opacity: 0 }}>
          <Header />
          <div data-sroll-section id="scrollTarget">


            <Filters actives={activeElements} halfActives={halfActiveElements}>
              <FilterItem data={productCategories} name="Product Category" label="Categoría" callback={callback} activeFilters={homeFilter} clear={clear}/>
              <FilterItem data={industriesCategories} name="Industries" label="Industrias" callback={callback} activeFilters={homeFilter} clear={clear}/>
              <FilterItem data={channelsCategories} name="Channels" label="Canales" callback={callback} activeFilters={homeFilter} clear={clear}/>
              <FilterItem data={integrationsCategories} name="Integrations" label="Integraciones" callback={callback} activeFilters={homeFilter} clear={clear}/>

              {halfActiveElements + activeElements > 0 && (
                <Button type="ghost" to="" small callback={handleClearFilters}>Limpiar</Button>
              )}
            </Filters>
            <div className="companies-container" >
            {contextData.data && ready && contextData.data.map((item, index) => (
              <CompanyItem  key={index} id={index} data={item} activeFilters={homeFilter} clear={clear} callback={( e => handleItemChange(e))} />
            )) }
            </div>
          </div>
          <AddCompanyBlock/>
          <Footer/>
        </motion.section>

      </main>

    </>
  );
}

export default Home;
