import './styles/main.scss';

import Home from "components/pages/Home";
import Login from "components/pages/Login";

import AddCompany from "components/pages/AddCompany";
import CompanyReport from "components/pages/CompanyReport";
import DataContext from 'contexts/dataContext';
import { Switch, Route, useLocation, useHistory } from "react-router-dom";
// import { BrowserRouter as Router, Route, Switch, useLocation, useHistory} from "react-router-dom";
import React, { useEffect, useState } from "react";
import CSVData from "resources/data/landscape.csv"
import { readString } from 'react-papaparse';
import { AnimatePresence } from "framer-motion";

function App() {
  //LOGIN
  const [isLogged, setIsLogged] = React.useState(
    localStorage.getItem('logged') || ''
  );

  useEffect(() => {
    localStorage.setItem('logged', isLogged);
  }, [isLogged]);
  //LOGIN CALLBACK
  function loginCallback() {

    setIsLogged('logged');
  }
  //PAGE TRANSITION
  const [isFirstMount, setIsFirstMount] = useState(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
     const unlisten = history.listen(() => {
       isFirstMount && setIsFirstMount(false);
     });

     return unlisten;
  }, [history, isFirstMount]);
  //data context
  const [data, setData] = useState("");
  const [filter, setFilter] = useState("");
  const value = { data, setData, filter, setFilter };


  //Data management
  const [rawData, setRawData] = useState();
  const [parsedData, setParsedData] = useState();
  const [filterStructure, setFilterStructure] = useState();
  //filters
  const [activeFilters, setActiveFilters] = useState([])
  const [filtersChange, setFiltersChange] = useState(false);
  const [clearFilters, setClearFilters] = useState(false);


  //RAW DATA
  useEffect(() => {
    var rawFile = new XMLHttpRequest();
		rawFile.open("GET", CSVData, false);
		rawFile.onreadystatechange = () => {
			if (rawFile.readyState === 4) {
				if (rawFile.status === 200 || rawFile.status === 0) {
					var allText = rawFile.responseText;
          setRawData(allText);
				}
			}
		};
		rawFile.send(null);
  }, []);
  //RAW TEXT DATA IS LOADED
  useEffect(() => {
    //Set Data
    if(rawData){
        const parseData = readString(rawData, { header: true });
        setData(parseData.data);
        setParsedData(parseData)
    }



  }, [rawData]);


  useEffect(() => {
    //Create filter Structure
    if(parsedData){
      var fields = parsedData.meta.fields;
      var filterStructureTemp = [];
      fields.forEach((item, index) => {
        var filterItem = {'name':item, 'value':"Todos"};

        filterStructureTemp.push(filterItem);
      });
      setFilterStructure(filterStructureTemp);
      setFilter(filterStructureTemp)

    }



  }, [parsedData]);





  //CHANGE FILTER CALLBACK

  //
  function filterCallback(value, filterName) {

      if(filterName === "Todos"){

        setActiveFilters([]);
        setClearFilters(!clearFilters);



      }else{
        setActiveFilters({value, filterName});
        setFiltersChange(!filtersChange);
      }
  }
  //Change filter requested
  useEffect(() => {

    //Change filter in Structure
    if(filterStructure && filter){

        var tempFilter = filterStructure;
        var tempIndex = 0;
        tempFilter.forEach((itemFilter, index) => {
          if(itemFilter.name.indexOf(activeFilters.filterName) !== -1){
            tempIndex = index;
          }
        })
        tempFilter[tempIndex].value = activeFilters.value;
        setFilter(tempFilter);
    }
  }, [filtersChange]);

  //CLEAR ALL FILTERS
  useEffect(() => {
    //Change filter in Structure
    if(filterStructure && filter){

        var tempFilter = filterStructure;
        var tempIndex = 0;
        tempFilter.forEach((itemFilter, index) => {
            tempIndex = index;
            tempFilter[tempIndex].value = "Todos";
        })
        //tempFilter[tempIndex].value = activeFilters.value;
        setFilter(tempFilter);
    }
  }, [clearFilters]);

  //FILTER UPDATED
  useEffect(() => {

  }, [filter]);


  return (

      <DataContext.Provider value={[value]}>



            <div className="App">
              {isLogged !== "logged" && (

                  <Login callback={loginCallback} />

              )}
              {isLogged === "logged" && (
              <AnimatePresence exitBeforeEnter>
                  <Switch location={location} key={location.pathname}>

                    <Route path="/" exact>
                      <Home callback={filterCallback} />
                    </Route>
                    <Route path="/add-company" exact>
                      <AddCompany />
                    </Route>

                    <Route path="/company/:id/report" exact>
                      <CompanyReport />
                    </Route>

                  </Switch>
                </AnimatePresence>
              )}

            </div>




    </DataContext.Provider>

  );
}

export default App;
//

//
