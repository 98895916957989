import { Component } from "react";
import "./Header.scss";
import Button from "components/ui/button/index.jsx";
import Brand from "resources/images/bebot-brand.svg";

export default class Header extends Component {
  render() {


    let className = "header" ;

    return (
      <div className={className} >
        <div className="logo" >
          <img src={Brand} alt="BeBot"/>
        </div>
        <div className="title" >
          Conversational Landscape
        </div>
        <div className="nav" >
          <Button to="add-company" small >Agregar Plataforma</Button>
        </div>
      </div>
    );
  }
}
