import { useEffect, useState } from 'react';
import "./FilterItem.scss";


export default function FilterItem({ children, data, label, callback, activeFilters, name, clear }) {
    const [activeOption, setActiveOption] = useState();
    const [manualChange, setManualChange] = useState(0);


    let className = "filterItem" + (activeOption !=="Todos" ? " active" : "");

    function handleSelectChange(e) {
      setActiveOption(e.target.value);
      setManualChange(manualChange + 1);

    }
    useEffect(() => {

      setActiveOption("Todos");

    }, [clear]);

    useEffect(() => {
      
      var currentFilters;
      if(activeFilters && activeFilters.filter ){


          currentFilters = activeFilters.filter;
          currentFilters.forEach((item, index) => {

            if(item.name === name){

              if(item.value !== "Todos"){
                setActiveOption(item.value);
              }
            }
          })
      }

    }, [activeFilters]);

    useEffect(() => {

      var tempOption;
      var currentFilters;


      if(activeFilters && activeFilters.filter){
        currentFilters = activeFilters.filter;
        currentFilters.forEach((item, index) => {

          if(item.name === name){
            tempOption = activeOption;
          }
        })
      }else{
        tempOption = "Todos"

      }
      if(manualChange > 0){
        callback(tempOption, name);
      }else {
        setActiveOption(tempOption);
      }


    }, [manualChange]);



    return (
      <div className={className} >
          <label htmlFor={label}>{label}:</label>
          <select name={label} id={label} onChange={( e => handleSelectChange(e))} value={activeOption}>
            <option  value="Todos">Todos</option>
            {data && data.map((item, index) => (
              <option key={index} value={item}>{item}</option>
            ))}
          </select>

      </div>
    );

}
