import {Helmet} from "react-helmet";
import React, { useEffect, useState } from "react";
import Button from "components/ui/button/index.jsx";
import locomotiveScroll from "locomotive-scroll";
import { motion } from "framer-motion";


function AddCompany() {
  //Scroll Div
  const scrollRef = React.createRef();
  const [scroll, setScroll] = useState();

  useEffect(() => {
    var scrollBars = document.getElementsByClassName("c-scrollbar");
    while(scrollBars.length > 0){
         scrollBars[0].parentNode.removeChild(scrollBars[0]);
     }
      if(!scroll){
        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          reloadOnContextChange: true,
          smooth: true
        });
        setScroll(scrollObj);
      }else {
        scroll.destroy();

        var newScrollBars = document.getElementsByClassName("c-scrollbar");
        while(newScrollBars.length > 0){
             newScrollBars[0].parentNode.removeChild(newScrollBars[0]);
         }

        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          smooth: true
        });
        setScroll(scrollObj);
      }
  }, []);

  return (

    <>
      <Helmet>
          <title>Agrega una plataforma o compañía al Landscape. BeBot Conversational Landscape</title>
      </Helmet>
      <main className="main white" id="scroll-report" data-scroll-container ref={scrollRef}>
        <motion.section exit={{ opacity: 0 }}>
          <div data-scroll-section>

            <h2>Agregar Plataforma</h2>
            <p>Completa nuestro formulario para agregar una plataforma al Landscape.</p>
            <p>El formulario te guiará por los diferentes aspectos que debes analizar en una plataforma y ordenará los datos para que puedan ser visualizados en esta herramienta.</p>
            <br/>



            <Button to="https://forms.gle/cBEK6sBKw9Eq441g6" external>Ir al Formulario</Button>
            <Button to="/" type="ghost" >Volver</Button>


          </div>

        </motion.section>
      </main>

    </>
  );
}

export default AddCompany;
