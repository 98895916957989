import {Helmet} from "react-helmet";
import React, { useEffect, useState } from "react";

import Button from "components/ui/button/index.jsx";
import Background from "resources/images/bgr.png";
import Brand from "resources/images/bebot-brand.svg";
import locomotiveScroll from "locomotive-scroll";
import { motion } from "framer-motion";
import "styles/pages/login.scss";


function Login({callback}) {
  //Scroll Div
  const scrollRef = React.createRef();
  const [scroll, setScroll] = useState();
  const [inputValue, setInputValue] = useState('');
  const [formError, setFormError] = useState(false);
  const [fieldEmpty, setFieldEmpty] = useState(true);
  let inputClassName = "input" + (formError ? " input--error" : "");

  useEffect(() => {
    var scrollBars = document.getElementsByClassName("c-scrollbar");
    while(scrollBars.length > 0){
         scrollBars[0].parentNode.removeChild(scrollBars[0]);
     }
      if(!scroll){
        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          reloadOnContextChange: true,
          smooth: true
        });
        setScroll(scrollObj);
      }else {
        scroll.destroy();

        var newScrollBars = document.getElementsByClassName("c-scrollbar");
        while(newScrollBars.length > 0){
             newScrollBars[0].parentNode.removeChild(newScrollBars[0]);
         }

        const scrollObj = new locomotiveScroll({
          el: scrollRef.current,
          smooth: true
        });
        setScroll(scrollObj);
      }
  }, []);
  //HANDLE INPUT Change
  function handleInputChange(event) {


    setFormError(false);
    if(event.target.value.length > 0){
      setInputValue({value: event.target.value});
      setFieldEmpty(false);
    }else {
      setFieldEmpty(true);
    }
  }
  //CHECK PASSWORD
  function handleSetPassword(e) {
    e.preventDefault();


      if(inputValue.value === "BeBot2021"){
        callback();
      }else {
        if(inputValue.value.length > 0){
          setFormError(true);
        }
      }

  }

  function handleKeyPress(e) {
    if(e.key === 'Enter'){

      if( !fieldEmpty ){

        handleSetPassword(e);
      }
    }
  }

  return (

    <>
      <Helmet>
          <title>Ingresá al Landscape. BeBot Conversational Landscape</title>
      </Helmet>
      <main className="main white login" id="scroll-login" data-scroll-container ref={scrollRef}>
        <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <div data-scroll-section>
            <img src={Brand} alt="BeBot"/>
            <h2  >Hola 👋, bienvenidos al BeBot Conversational Landscape</h2>
            <p>Aquí encontrarás un compilado de plataformas de tecnología y servicios del mundo conversacional para construir bots útiles, únicos e irrepetibles.
¡Ayúdanos a sumar conversational tools y a pintar de colores este landscape!</p>
            <p>Para entrar al Landscape ingresa la contraseña.</p>

            <div>
              <input className={inputClassName} type="text" onChange={handleInputChange} onKeyPress={handleKeyPress} placeholder="Escribe la contraseña"/>
              <Button disabled={fieldEmpty} callback={handleSetPassword}  >Entrar</Button>
            </div>
            {formError && (
              <p className="errorMessage">
                La contraseña es incorrecta.
              </p>
            )}

            <div className="background" data-scroll data-scroll-position="bottom" data-scroll-class="visible"  data-scroll-delay="1" data-scroll-repeat="true">
              <img src={Background} alt="BeBot"/>
            </div>







          </div>

        </motion.section>
      </main>

    </>
  );
}

export default Login;
