import { Component } from "react";
import { Link} from 'react-router-dom'
import "./Button.scss";


export default class Button extends Component {
  render() {
    const { children, type, to, external, small, callback, disabled } = this.props;

    let className = "button" + (type ? " button--" + type : "") + (small ? " button--small" : "") + (disabled ? " button--disabled" : "");

    var buttonElement;
    if(external){
      buttonElement=(
        <a href={to}  rel="noreferrer" target="_blank" className={className}>

            {children}

        </a>
      );
    }else{
      if(callback){
        buttonElement=(
          <div className={className} onClick={( e => handleClick(e))}>

              {children}

          </div>
        );
      }else {
        buttonElement=(
          <Link to={to} className={className}>

              {children}

          </Link>
        );
      }

    }

    //CLEAN FILTERS function
    function handleClick(e) {
      e.preventDefault();
      callback(e);
    }


    return (
      <>
      {buttonElement}
      </>
    );
  }
}
