import { Component } from "react";
import "./Tag.scss";


export default class Tag extends Component {
  render() {
    const { children,  small, color } = this.props;

    let className = "tag" + (color ? " tag--" + color : "") + (small ? " tag--small" : "");



    return (
      <>
        <div className={className} data-scroll>
            {children}
        </div>
      </>
    );
  }
}
