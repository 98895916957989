import React from "react";

const DataContext = React.createContext({
  data: [],
  setData: () => {},
  filter: [],
  setFilter: () => {},
});

export default DataContext;
