import { Component } from "react";
import Button from "components/ui/button/index.jsx";
import Background from "resources/images/bgr.png";
import "./AddCompanyBlock.scss";


export default class AddCompanyBlock extends Component {
  render() {


    let className = "addCompanyBlock" ;

    return (
      <div className={className}  >
        <h2  data-scroll data-scroll-speed="1">Colabora y haz crecer<br/>este Landscape</h2>

        <div data-scroll data-scroll-speed="2">
          <Button to="add-company">Agrega una plataforma</Button>
        </div>
        <div className="background" data-scroll data-scroll-position="bottom" data-scroll-class="visible"  data-scroll-delay="1" data-scroll-repeat="true">
          <img src={Background} alt="BeBot"/>
        </div>
      </div>
    );
  }
}
