
import DataContext  from 'contexts/dataContext';
import React, { useContext } from "react";
import "./Filters.scss";


export default function Filters({ children, actives, halfActives }) {

    const [contextData, setContextData] = useContext(DataContext);
    const [contextFilters, setContextFilters] = useContext(DataContext);
    let className = "filters";

    return (
      <div className={className} data-scroll data-scroll-sticky data-scroll-target="#scrollTarget">
        {children}
        <div className="activeItems ">
          {halfActives > 0 && (
            <div className="activeItem half">
              {halfActives}
              <div className="tooltip">
                {halfActives > 1 && (
                  <span>
                    <strong>{halfActives}</strong> plataformas coinciden al menos con uno de los filtros activos.
                  </span>
                )}
                {halfActives === 1 && (
                  <span>
                    <strong>{halfActives}</strong> plataforma coincide al menos con uno de los filtros activos.
                  </span>
                )}

              </div>
            </div>
          )}
          {actives > 0 && (
            <div className="activeItem full">
              {actives}
              <div className="tooltip">

                {actives > 1 && (
                  <span>
                  <strong>{actives}</strong> plataformas coinciden con todos los filtros activos.
                  </span>
                )}
                {actives === 1 && (
                  <span>
                    <strong>{actives}</strong> plataforma coincide con todos los filtros activos.
                  </span>
                )}
              </div>
            </div>
          )}

          <div className="activeItem count">
            {contextData.data.length}
            <div className="tooltip">
              Hay un total de <strong>{contextData.data.length} plataformas</strong> en el landscape.
            </div>
          </div>

        </div>
      </div>
    );

}
