import "./CompanyItem.scss";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import Tag from "components/ui/tag/index.jsx";



export default function CompanyItem({ children, data, activeFilters, id, callback, clear }) {
    const [active, setActive] = useState(false);
    const [halfActive, setHalfActive] = useState(false);

    let className = "companyItem"
     + (active ? " active" : "")
     + (halfActive ? " halfActive" : "");

  //Clear
  useEffect(() => {
    setActive(false);
    setHalfActive(false);

  }, [clear]);
  //CHECK IF SHOULD BE ACTIVE
   useEffect(() => {

     var itemStatus = 0;
     var filterStatus = 0;
     if(activeFilters.filter){
       var categories = [];
       //Put all filters that are not "Todos" in an array

       activeFilters.filter.forEach((item, index) => {

         if(item.value !== "Todos"){
           categories.push(item);
         }
       });

       //Check for each category of filter
       filterStatus = categories.length;
       if(filterStatus > 0){
         //For each category
         categories.forEach((itemCategory, index) => {

           var itemsToFilter = data[itemCategory.name];

           if(itemsToFilter && itemsToFilter.length > 0){

             let parsed = itemsToFilter.split(', ')
             //For each tag in the item
             parsed.forEach((itemToFilter, index) => {

               if(itemToFilter === itemCategory.value){
                itemStatus++
               }
             });
           }
           //
         });


         if(itemStatus > 0){
           if(itemStatus >= filterStatus){
             setActive(true);
             setHalfActive(false);
           }else{
             setHalfActive(true);
             setActive(false);
           }
         }else{
           setActive(false);
           setHalfActive(false);
         }
       }else {
         setActive(false);
       }

     }
     callback(data.Name);
   }, [activeFilters]);
   //
    return (
      <div className={className}  >
        <div className="itemName" data-scroll data-scroll-class="visible" data-scroll-repeat="true">
          <Link to={`/company/${id}/report`}>{data.Name}</Link>

        </div>
        <div className="itemInfoCard" data-scroll >
          <div className="infoCardContent" >
            {data[ 'Product Category' ] && data[ 'Product Category' ].length > 0 &&(
              <div className="infoCardCategory">
                <h4>Categoría</h4>
                <div>
                  {data[ 'Product Category' ] && data[ 'Product Category' ].split(', ').map((item, index) => (
                    <Tag small key={index}>{item}</Tag>
                  ))}
                </div>

              </div>
            )}
            {data[ 'Industries' ] && data[ 'Industries' ].length > 0 &&(
              <div className="infoCardCategory">
                <h4>Industrias</h4>
                <div>
                  {data[ 'Industries' ] && data[ 'Industries' ].split(', ').map((item, index) => (
                    <Tag small key={index}>{item}</Tag>
                  ))}
                </div>
              </div>
            )}
            {/*data[ 'Channels' ] && data[ 'Channels' ].length > 0 &&(
              <div className="infoCardCategory">
                <h4>Canales</h4>
                {data[ 'Channels' ]}
              </div>
            )}
            {data[ 'Integrations' ] && data[ 'Integrations' ].length > 0 &&(
              <div className="infoCardCategory">
                <h4>Integraciones</h4>
                {data[ 'Integrations' ]}
              </div>
            )*/}
          </div>
          <div className="infoCardLinks">
            <Link to={`/company/${id}/report`}>Ver Reporte Completo</Link>
          </div>
        </div>
      </div>
    );

}
