import { Component } from "react";
import "./Footer.scss";


export default class Footer extends Component {
  render() {


    let className = "footer" ;

    return (
      <div className={className} >
        <div className="info">
          BeBot. Compañía de diseño conversacional. 2021.
        </div>
        <div className="link">
          <a rel="noreferrer" href="https://bebot.design" target="_blank">
            www.bebot.design
          </a>
        </div>
      </div>
    );
  }
}
